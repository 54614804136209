.center {
  border-radius: 1em;
  padding: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.logo {
  padding: 1em;
  text-align: center;
}

.contactInfo {
  padding-top: 1em;
  text-align: center;
  font-size: 1em;
}
