.inView {
  padding-top: 50px;
  font-size: 5vmin;
}

.wrapper {
  padding: 10px;
}

.buttonW {
  width: "100px" !important;
  height: "30px" !important;
}
.actions {
  text-align: right;
  padding: 10px;
}

.margin-left {
  margin-left: 80px !important;
}

.buttonPadding {
  padding: 10px;
}

.textAlignRight {
  text-align: right;
}

.totalAlightRight {
  text-align: right;
  padding: 10px;
}

.paddingTop {
  padding: 5px;
}

.test {
  border-color: red;
  border-style: solid;
}

.optionsPadding {
  padding-bottom: 15px;
}

.optionCenter {
  text-align: center;
  padding: 15px;
}

.modal-footer-padding {
  padding-left: 10px;
}
